import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Establecimiento,
    Establecimientos,
    Lugar,
    Lugares,
    Usuario,
    UsuarioRegistroBase,
    UsuarioRegistroBaseForm,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"

@Component({
    selector: "register-preuuss",
    templateUrl: "./register-preuuss.component.html",
    styleUrls: ["./register-preuuss.component.scss"]
})
export class RegisterPREUUSSComponent implements OnInit, OnDestroy {
    usuario: UsuarioRegistroBase
    params: any = UsuarioRegistroBaseForm.formParams
    form: UntypedFormGroup
    saved: boolean = false
    pais: string = config.plataforma.pais
    identificador: any[string] =
        config.plataforma.identificadorUsuario[0].toLowerCase() == "ti"
            ? "cc"
            : config.plataforma.identificadorUsuario[0].toLowerCase()
    identificadorAlias: string =
        config.plataforma.identificadorUsuarioAlias?.[this.identificador] || this.identificador.toUpperCase()
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams
    usuarioPaisForm: UntypedFormGroup
    enableCarreras = config.registro ? config.registro.enableCarreras : false
    universidades = config.registro ? config.registro.universidades || [""] : [""]
    queryParamsSub: Subscription
    extraParams = {}

    @Input() enableFechaNacimiento: boolean // TODO: Agregarlo a clonador
    enableTelefono: boolean
    enableNivel: boolean
    enableColegio: boolean
    mensajeFin: string

    colegioObligatorio: boolean
    carrerasObligatorio: boolean

    triedToSubmit: boolean = false

    lugar: Lugar
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    filteredEstablecimientos: Establecimiento[]

    @Input() formParamsRequired: string[] = []
    @Input() showInputColegioEgresado: boolean = false
    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    soporteMail: string = config.plataforma.info.soporte.mail

    constructor(
        protected router: Router,
        protected injector: Injector,
        protected usuariosService: Usuarios,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos,
        protected activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.queryParamsSub = this.activatedRoute.queryParams.subscribe((map: Params) => {
            console.log(map)
            this.extraParams = map
        })
        this.addRequiredToParams()

        this.mensajeFin = config.registro.mensajeRegistro
            ? config.registro.mensajeRegistro
            : `Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
minutos más, por favor revisa tu carpeta de spam.`
        this.enableTelefono = config.registro?.enableTelefono || false
        this.enableNivel = config.registro?.enableNivel || false
        this.enableColegio = config.registro?.enableColegio || false
        this.enableFechaNacimiento = config.registro?.enableFechaNacimiento || false

        this.colegioObligatorio = config.registro?.colegioObligatorio || false
        this.carrerasObligatorio = config.registro?.carrerasObligatorio || false

        this.usuario = new UsuarioRegistroBase()
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        this.form = UsuarioRegistroBaseForm.getForm(this.usuario, this.params, this.injector)
        this.usuarioPaisForm = this.form.controls["usuario_" + this.pais] as UntypedFormGroup
    }

    addRequiredToParams() {
        const paramsNames = Object.keys(this.params)
        this.formParamsRequired.forEach(param => {
            if (paramsNames.includes(param)) {
                this.params[param].validations = this.params[param].validations || []
                this.params[param].validations = this.params[param].validations.concat([Validators.required])
            }
        })
    }

    save() {
        this.triedToSubmit = true
        UsuarioRegistroBaseForm.markFormControlsAsTouched(this.form)
        UsuarioRegistroBaseForm.markFormControlsAsDirty(this.form)

        this.params.colegio.validations = (this.params.colegio.validations ?? []).filter(v => v != Validators.required)
        this.form.controls.colegio.setValidators(this.params.colegio.validations)
        if (
            this.colegioObligatorio &&
            !this.usuario.colegio &&
            (+this.usuario.nivel_id != 13 || this.showInputColegioEgresado)
        ) {
            this.params.colegio.validations.push(Validators.required)
            this.form.controls.colegio.setValidators(this.params.colegio.validations)
            this.form.controls.colegio.updateValueAndValidity()

            return
        }

        if (this.form.valid) {
            let user = this.usuario.toUsuario()
            user.plataforma_id = config.plataforma.id
            const utmParamsFromStorage = JSON.parse(localStorage.getItem("utmParams"))
            user["utm_params"] = utmParamsFromStorage ? utmParamsFromStorage : this.extraParams
            this.usuariosService.save(user).then(response => {
                this.onTitleChange.emit("¡Ya casi estás listo!")
                this.saved = true
                setTimeout(() => {
                    this.router.navigate(["landing"])
                }, 5000)
            })
        }
    }

    back() {
        this.router.navigate([""])
    }

    updatePreferencias(preferencias) {
        this.usuario.preferenciaCarreraSedes = preferencias
    }

    updateLugar(lugar: any) {
        if (lugar instanceof Lugar) {
            this.usuario.lugar = lugar
            this.lugar = lugar
        }
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: ["Comuna"] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuario.colegio = establecimiento
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where(this.whereGetEstablecimientos(establecimientoFilter))
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        let lugarTipos = ["Ciudad", "Región", "Provincia", "Comuna"]
        if (this.pais == "colombia") {
            lugarTipos = ["Municipio", "Departamento"]
        }

        let establecimientoParams = { establecimiento: establecimientoFilter }
        if (this.lugarColegio) {
            establecimientoParams["lugar_id"] = this.lugarColegio.id
        }

        return {
            per: 100,
            raw: 1,
            include: `[establecimiento_${this.pais}]`,
            establecimiento: establecimientoParams,
            lugar_tipo: { lugar_tipo: lugarTipos }
        }
    }

    ngOnDestroy() {
        this.queryParamsSub?.unsubscribe()
    }
}
