import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { LoginComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    @Input() enableRegister = false
    @Input() useInternalFooter: boolean = false
    config: typeof config
    landingImage: string = ""
    logo: string
    logoFooter: string
    titulo: string
    logosExtra: any[]
    externalRegisterUrl: string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }

        this.landingImage = "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/preuuss/landing/fondo-preuus-2024.png"

        this.titulo = this.config.app.baseStyle?.landing?.subtitle ? this.config.app.baseStyle?.landing?.subtitle : ""

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoMediumLight

        // this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
        //     ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
        //     : this.config.app.assets.logoMediumLight

        this.logoFooter =
            "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/preuuss/landing/logo-uss-35-an%CC%83os-blanco.png"

        this.logosExtra = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []

        this.externalRegisterUrl = "https://preuniversitario.uss.cl/"
    }
}
