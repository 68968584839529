<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="caja-wrap">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="background-mobile"></div>
            <div class="caja-container">
                <div class="img-superior-container">
                    <img
                        src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/preuss-logo-new-uss-241023.png"
                    />
                </div>

                <div class="caja caja-inicio">
                    <div class="img-credenciales-container">
                        <div class="texto-credenciales">
                            <h1><strong>Preuniversitario USS</strong></h1>
                            <h3>PAES 2024</h3>
                            <br />
                            <h4>TU RESULTADO</h4>
                            <h2 style="color: #cfb47c"><strong>
                                PAES
                                <br />
                                ---------
                            </strong></h2>
                            <h4>
                                DEPENDE DE <br /> 
                                LA DECISIÓN <br />
                                QUE TOMES <br />
                            </h4>
                            <h4 style="color: #cfb47c"><strong>HOY</strong></h4>
                        </div>
                    </div>

                    <div class="titulo-mobile">
                        <h1><strong>Preuniversitario USS</strong></h1>
                            <h3>PAES 2024</h3>
                            <br />
                    </div>

                    <div class="caja-inner-margin centered">
                        <div class="login-wrap">
                            <div class="circulo">
                                <fa name="user-circle-o"></fa>
                            </div>
                            <login-standard
                                class="login"
                                [registerButtonType]="'secondary'"
                                [enableContactUs]="false"
                                [enableRegister]="enableRegister"
                                [enableFacebookLogin]="false"
                                [enablePasswordRecovery]="true"
                                [externalRegisterUrl]="externalRegisterUrl"
                            ></login-standard>
                        </div>
                        <br />
                    </div>

                    <div class="subtitulo-mobile">
                        <h4>TU RESULTADO <span style="color: #cfb47c"><strong>PAES</strong></span></h4>
                        <strong style="color: #cfb47c">----------------</strong>
                        <br />
                        <h4>
                            DEPENDE DE LA DECISIÓN <br />
                            QUE TOMES <span style="color: #cfb47c"><strong>HOY</strong></span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <footer>
            <div>
                <div class="logo-contactanos-container">
                    <img
                        class="footer-logo"
                        src="{{ logoFooter }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                    <div class="footer-col">
                        <h3 class="contactanos">Contáctanos</h3>
                        <ul>
                            <li>
                                <p>
                                    <fa name="envelope-o"></fa>
                                    &nbsp;{{ config.plataforma.info.contacto.mail }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="logos-extra-container" *ngFor="let logo of logosExtra">
                    <a href="{{ logo.link }}" class="acreditacion">
                        <img class="footer-logo" src="{{ logo.src }}" alt="{{ logo.title }}" title="{{ logo.title }}" />
                    </a>
                </div>
            </div>
            <div class="text-center politicas-link">
                <a href="https://www.uss.cl/politicas-de-privacidad-uss" target="_blank">Ver politicas de privacidad</a>
            </div>
        </footer>
    </div>
</div>
