import { SharedLayoutComponent } from "@puntaje/platforms/preuuss"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/preuuss"
import {
    UsuariosEditComponent,
    UsuarioPerfilAlumnoComponent,
    TutorConfirmarComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    IncompleteRegisterComponent,
    RegisterComponent,
    CompletarRegistroSocialComponent,
    OpcionesRegistroNacimientoComponent,
    RegistroComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LogrosPerfilComponent } from "./logros-perfil/logros-perfil.component"

import { RegisterCompletarClonesComponent, RegisterPREUUSSComponent } from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [TokenGuard, AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent }]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: RegistroComponent,
                data: {
                    formParamsRequired: ["telefono", "colegio", "lugarColegio", "nivel_id"],
                    showInputColegioEgresado: true,
                    component: RegisterPREUUSSComponent
                }
            }
        ]
    },
    {
        path: "usuarios/opciones",
        component: SharedLayoutComponent,
        children: [{ path: "", component: OpcionesRegistroNacimientoComponent }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: CompletarRegistroComponent,
                canActivate: [TokenGuard],
                data: { component: RegisterCompletarClonesComponent }
            }
        ]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroSocialComponent }]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { tab: "" }
            }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    {
        path: "usuarios/:id/logros",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { tab: "" }
            }
        ]
    },
    {
        path: "usuario/:id/logros",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: LogrosPerfilComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [TokenGuard, AuthGuard] }
    {
        path: "tutor/confirmar/:token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: TutorConfirmarComponent }]
    },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        children: [{ path: "", component: IncompleteRegisterComponent }]
    }
]

export const routing = RouterModule.forChild(routes)
