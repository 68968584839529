import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PuntajeUsuariosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { LandingModule } from "@puntaje/puntaje/new-modules/landing"
import { LogrosModule } from "@puntaje/puntaje/new-modules/logros"

import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { routing } from "./alumnos_usuarios.routing"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedLayoutsModule } from "@puntaje/platforms/preuuss"

import { LogrosPerfilComponent } from "./logros-perfil/logros-perfil.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        UtilModule,
        UsuariosModule,
        ModelViewModule,
        routing,
        LandingModule,
        SharedLayoutsModule,
        Angular2FontawesomeModule,
        MaterialesModule,
        LogrosModule,
        PuntajeUsuariosModule,
        CUILayoutsModule
    ],
    declarations: [LogrosPerfilComponent],
    exports: []
})
export class AlumnosUsuariosModule {}
